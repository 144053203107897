$primary: #2196f3;
$secondary: #f50057;

$success: rgb(76, 175, 80);
$warning: rgb(255, 152, 0);

$textPrimary: #fff;
$textSecondary: rgba(255, 255, 255, 0.7);

$background: #303030;
$backgroundPaper: #424242;

$divider: rgba(255, 255, 255, 0.12);

html,
body {
    margin: 0px;
    padding: 0px;
}

body {
    font-family: 'Arimo', sans-serif;
    font-size: 18px;
    color: black;
    background-color: $background;
    color: $textPrimary;
}

h1 {
    font-size: 200%;

    .MuiChip-root {
        margin-left: 16px;
    }
}

.layout {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .logo {
        a {
            color: $textPrimary;
            text-decoration: none;

            display: flex;
            align-items: center;

            img {
                width: 40px;
                height: 40px;
                margin-right: 8px;
            }
        }
    }

    .main-content {
        margin-top: 64px;
        margin-bottom: 32px;
    }

    .user-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 16px;

        p {
            padding-left: 8px;
        }
    }
}

footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 85%;
    padding: 8px 16px;
    background-color: $backgroundPaper;
}

.flex {
    display: flex;
}

.grow-1 {
    flex-grow: 1;
    flex-basis: 100px;
}

.photos-grid {
    margin-top: 32px;
    margin-bottom: 32px;
}

// ------------ Competition ---------------
.competition-header {
    display: flex;
    flex-direction: row;
    margin-top: 16px;

    .competition-image {
        height: 300px;
        max-width: 500px;
        margin-right: 32px;
    }

    .votes {
        margin-top: 64px;
    }
}

.photos {
    img {
        cursor: pointer;
    }

    .MuiGridListTileBar-actionIcon {
        white-space: nowrap;
    }
}

.vote-number {
    font-size: 120%;
    padding-right: 16px;
}

// --------------- Edit --------------

.image-preview {
    display: flex;
    flex-direction: column;
    align-items: center;

    .control {
        text-align: center;
    }
}

// --------------- Show --------------

.show.MuiBackdrop-root {
    z-index: 1200; // header is 1100
    flex-direction: column;
    align-items: stretch;

    .top {
        text-align: right;
    }

    .center {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        flex-basis: 100px;
        align-items: stretch;

        .left,
        .right {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        .photo {
            flex-grow: 1;
            flex-basis: 100px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
        }
    }

    .bottom {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-left: 32px;
        padding-right: 32px;
        padding-bottom: 16px;

        .title {
            font-weight: bold;
            font-size: 120%;
            padding-bottom: 8px;
        }
    }
}

// -------- Material Components -----------

div.MuiCircularProgress-root {
    display: block;
    margin: 32px auto;
}

.MuiButtonBase-root {
    &.fab {
        position: fixed;
        z-index: 10;
        right: 32px;
        bottom: 32px;
    }

    &.fab-1 {
        bottom: 32px;
    }

    &.fab-2 {
        bottom: 108px;
    }
}

// ---------------- TypeForm -----------------

html .type-form-input {

    label {
        color: $textSecondary;
    }

    input,
    textarea,
    select {
        font-family: 'Arimo', sans-serif;
        color: $textPrimary;
        background-color: $backgroundPaper;
        border-bottom: 1px solid $primary;

        &:focus {
            border-bottom: 1px solid lighten($primary, 20%);
        }

        &[readonly] {
            &:focus {
                border-bottom: 1px solid $primary;
            }
        }
    }

    .message {
        color: $success;
    }

    &.not-valid {
        input {
            background-color: fade-out($warning, 0.7);
            border-bottom: 1px solid $warning;
        }

        .message {
            color: $warning;
        }
    }

    .checkbox {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .flex-row {
        align-items: flex-start;
    }
}